.GateBody {
	color: inherit;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border: 2px solid red;
}

.EditorContainer {
	flex: 1;
	overflow: auto;
	height: calc(100% - 30px);
	/* prevents scroll events from reaching the canvas when the end of the scroll area is reached */
	overscroll-behavior: contain;
}

.MilkdownEditor {
	height: 100%;
	width: 100%;
}

.MilkdownEditor :global(.milkdown) {
	height: 100%;
	padding: 8px;
}

.MilkdownEditor :global(.milkdown-menu) {
	display: none;
}

.Editing .MilkdownEditor :global(.milkdown-menu) {
	display: flex;
}

.ReadOnly {
	cursor: default;
}

div.WrapperClass {
	/* IMPORTANT: This makes notes appear behind all widgets */
	z-index: 301;
	background-color: transparent;
	border: unset !important;
}

div.WrapperClass :global(.cg-body) {
	background-color: transparent;
}

.Toolbar {
	width: 120px;
	height: 30px;
	position: absolute;
	z-index: 1;
	border-bottom: 1px solid;
	border-left: 1px solid;
	
	right: 0px;

	padding-right: 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.Toolbar.NotEditing {
	border: none;
	padding-right: 5px;
}

.Toolbar svg {
	fill: inherit;
}

.Toolbar .DragIcon {
	margin-right: 10px;
	cursor: move;
}

.Toolbar .ColorPickerBtn {
	width: 18px;
	height: 18px;
	padding: 4px;
	border: 1px solid #000;
	border-radius: 4px;
}

.ResizeHandle svg {
	fill: red;
}

.EditorContainer :global([data-milkdown-root="true"]) {
	height: 100%;
}

.EditorContainer :global(.milkdown) {
	background-color: transparent;
}

.EditorContainer :global(.milkdown .editor[role="textbox"] *) {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
}

.EditorContainer :global(.milkdown .editor[role="textbox"]) {
	padding: 10px 35px;
}

.EditorContainer.InCustomWidget :global(.milkdown .editor[role="textbox"])::selection {
	color: #2a2a2a;
}

.EditorContainer.InCustomWidget :global(.milkdown .editor[role="textbox"]) {
	color: #f0f0f0;
}

.EditorContainer.InCustomWidget :global(.milkdown .editor[role="textbox"] milkdown-image-block input.caption-input),
.EditorContainer.InCustomWidget :global(.milkdown .editor[role="textbox"] .crepe-placeholder:before) {
	color: #c0c0c0;
}

/* Remove default margin from headings */
.EditorContainer :global(.milkdown .editor[role="textbox"] h1:first-child),
.EditorContainer :global(.milkdown .editor[role="textbox"] h2:first-child),
.EditorContainer :global(.milkdown .editor[role="textbox"] h3:first-child),
.EditorContainer :global(.milkdown .editor[role="textbox"] h4:first-child),
.EditorContainer :global(.milkdown .editor[role="textbox"] h5:first-child),
.EditorContainer :global(.milkdown .editor[role="textbox"] h6:first-child) {
	margin-top: 0px;
}


.EditorContainer :global(.milkdown milkdown-block-handle) {
	transform: translateX(10px);
}

/* This is the plus button that appears when you hover over a block */
.EditorContainer :global(.milkdown milkdown-block-handle .operation-item) {
	width: 24px;
	height: 24px;
	padding: 0px;
}

.EditorContainer :global(.milkdown milkdown-block-handle .operation-item:nth-child(2)) {
	display: none;
}

.FloatingColorMenu {
	position: absolute;
	right: 85px;
	top: 24px;
	z-index: 2;
}

/* Don't show current color since we use our own btn for this */
.Swatch {
	display: none;
}

.SwatchPopup{
	margin-top: 12px;
	padding-bottom: 30px;
	position: fixed;
}

/* ---- Read only mode ---- */

/* Hide placeholder text and plus button */
.EditorContainer.NotEditing :global(.milkdown .editor[role="textbox"] .crepe-placeholder),
.EditorContainer.NotEditing :global(.milkdown milkdown-block-handle) {
	display: none;
}

/* Prevent selecting or moving images */
.EditorContainer.NotEditing :global(.milkdown .editor[role="textbox"] milkdown-image-block) {
	pointer-events: none;
}

/* Removes background effect on image hover */
.EditorContainer.NotEditing :global(.milkdown .editor[role="textbox"] milkdown-image-block .image-wrapper::before) {
	background: none;
}
