.CompactInput {
	height: 32px;
}

.GeneratingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.GeneratingContainer :global(.kemu-spin-nested-loading) {
	width: 100%;
}

.ModeGroup {
	display: flex;
	flex-direction: column
	
}

.Content {
	margin-bottom: 50px;
}
.ModeDescription {
	/* margin-top: 0px; */
}

.ModeDropdown {
	margin-bottom: 8px;
}

/* New styles for the path selection UI */
.SelectedPath {
	margin-bottom: 8px;
	padding-left: 4px;
	color: #6d757e;
}

.PathHelper {
	margin-bottom: 8px;
	padding-left: 4px;
	color: var(--kemu-color-danger);
}

/* Make sure the path information displays properly */
.SelectedPath, .PathHelper {
	width: 100%;
	display: block;
	font-style: italic;
}