.Backdrop {
	z-index: 11;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: black;
	opacity: 0.3;
	left: 0px;
	top: 0px;
}

.Modal {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 11;
}

.Modal:not(.FullScreen) {
	padding: 20px;
}

.Container{
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 400;
}