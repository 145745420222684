.Content {
  /*  */
}

.OptionsGroup {
  width: 100%;
}

.Option {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.Option:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.OptionIcon {
  font-size: 24px;
  color: #1890ff;
}

.OptionTitle {
  font-weight: 500;
  margin-bottom: 4px;
}

.OptionDescription {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
} 