.SidebarContainer {
	height: 100%;
	overflow: hidden;
	/* padding-bottom: 80px; */
	display: flex;
	position: relative;
	flex-direction: column;
}

.ScrollbarContainer {
	height: calc(100% - 80px);
}

.SidebarBrand {
	display: inline-block;
	width: 100%;
	text-align: center;
	height: 80px;
	line-height: 70px;
	position: relative;
}


.PushDownItems {
	margin-top: auto;
	display: flex;
	flex-direction: column;
}
 
.CollapsedHeaderLogo {
	padding: 5px 5px;
	width: 50px;
	height: auto;
}

.HeaderLogo {
	padding: 0 5px;
	width: 160px;
	height: auto;
	animation: 0.2s logoFadeIn;
	animation-fill-mode: forwards;
  visibility: hidden;
	will-change: opacity, visibility;
}

@keyframes logoFadeIn {
	50% {
		opacity: 0;
		visibility: hidden;
	}
  90% {
    visibility: visible;
  }
  100% {
		opacity: 1;
    visibility: visible;
  }
}

.VersionBox {
	/* position: absolute; */
	bottom: 10px;
	width: 100%;
	text-align: center;
	font-size: 10px;
}

.VersionBox.Staging {
	color: #fc594e;
}

/* aligned items in popup menu */
.MenuItem {
	display: flex;
	align-items: center;
}

.Menu.Collapsed [role="menuitem"] {
	padding-top: 5px;
}

Menu.Collapsed .LargeMenu {
	height: 52px;
}

.Menu.Collapsed .LargeMenu [role="img"] {
	/* padding-inline: calc(50% - 13px - 4px); */
	margin-left: calc(50% - 13px);
}

.LargeMenu.Icon {
	font-size: 26px;
}

.ProfileMenu {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ProfileMenu button {
	width: 100% !important;
	margin: 4px;
	height: 40px;
}

.SaveDropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SaveDropdown:hover {
  color: #1890ff;
}

.HubUpdateButton {
	display: flex;
	align-items: center;
	justify-content: center;
}

.HubUpdateButton button {
	width: 100% !important;
  height: 50px;
}