.LayoutContent {
	margin: 0px 16px;
	margin-bottom: 16px;
	min-height: 280px;
	position: relative;
  user-select: none;
}



.LogicMapperContainer {
	box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
	border-radius: 10px;
	overflow: hidden;
}

.Section {
  display: flex;
  width: 100%;
  height: 100%;
}


.RecipeNameContainer {
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center; 
	z-index: 1;
  position: absolute;
  background-color: #e7e7e7;
  border-bottom-right-radius: 8px;
}

.RecipeNameContainer h1 {
  font-size: 20px;
	margin: 0px;
}

.RightSide {
  position: relative;
}