.Body {
  /* background-color: red; */
  color: white;
}

.Notification {
  color: white;
  background-color: #282828;
}

.Notification :global(.kemu-notification-notice-message) {
  color: var(--kemu-color-danger) !important;
}

.Notification :global(a.kemu-notification-notice-close) {
  color: white !important;
}

.ErrorMessage {
  color: var(--kemu-color-danger-lighten-20);
}

.WidgetLink {
  text-decoration: underline !important;
}

.WidgetName {
  font-weight: 700;
}

.Notification :global(.kemu-notification-notice-description) {
  color: white !important;
}

