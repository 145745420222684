.light-theme {
	
}

:root{
	--kemu-widget-port-compatible: 0 0 5px 3px #27ff00;
	--kemu-widget-port-maybe-compatible: 0 0 5px 3px #ffee00;
}

/** 
* Data types for Dynamic port detection:
* 0: Number
* 1: String
* 2: ArrayBuffer
* 3: Array
* 4: Boolean
* 5: JsonObj
* 6: Anything
* 7: ImageData
* 8: AudioBuffer
* 9: Rect
* 10: Point
*/


/* Number -> Number | Anything */
.lm-canvas.type-0 .gate-port.target-port.jtk-drag-active.type-0,
.lm-canvas.type-0 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Array -> Array | Anything */
.lm-canvas.type-3 .gate-port.target-port.jtk-drag-active.type-3,
.lm-canvas.type-3 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* BinaryFile -> BinaryFile | Anything */
.lm-canvas.type-12 .gate-port.target-port.jtk-drag-active.type-12,
.lm-canvas.type-12 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Boolean -> Boolean | Anything */
.lm-canvas.type-4 .gate-port.target-port.jtk-drag-active.type-4,
.lm-canvas.type-4 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* String -> String */
.lm-canvas.type-1 .gate-port.target-port.jtk-drag-active.type-1 {
	box-shadow: var(--kemu-widget-port-compatible);
}

/* String -> Anything */
.lm-canvas.type-1 .gate-port.target-port.jtk-drag-active.type-6 {
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Json -> Json | Anything */
.lm-canvas.type-5 .gate-port.target-port.jtk-drag-active.type-5 {
box-shadow: var(--kemu-widget-port-compatible);
}

/* Json -> Anything */
.lm-canvas.type-5 .gate-port.target-port.jtk-drag-active.type-6 {
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* ImageData -> ImageData | Anything*/
.lm-canvas.type-7 .gate-port.target-port.jtk-drag-active.type-7,
.lm-canvas.type-7 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* AudioBuffer -> AudioBuffer */
.lm-canvas.type-8 .gate-port.target-port.jtk-drag-active.type-8{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* AudioBuffer -> Anything */
.lm-canvas.type-8 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Rect -> Rect */
.lm-canvas.type-9 .gate-port.target-port.jtk-drag-active.type-9{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Rect -> Anything*/
.lm-canvas.type-9 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* Point -> Point */
.lm-canvas.type-10 .gate-port.target-port.jtk-drag-active.type-10{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Point -> Anything*/
.lm-canvas.type-10 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* Anything -> Anything*/
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-6{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Anything -> Json*/
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-5{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Anything -> Array */
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-3{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* Array -> Array */
.lm-canvas.type-3 .gate-port.target-port.jtk-drag-active.type-3{
	box-shadow: var(--kemu-widget-port-compatible);
}

/* Anything -> ImageData */
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-7{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* Anything -> Rect */
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-9{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

/* Anything -> Number */
.lm-canvas.type-6 .gate-port.target-port.jtk-drag-active.type-0{
	box-shadow: var(--kemu-widget-port-maybe-compatible);
}

.selection-container{
	height: 100%;
}


.selection-area::before {
	background-color: var(--kemu-color-secondary);
	opacity: 0.15;
	content: '';
	width: 100%;
	height: 100%;
	display: block;
}

.selection-area {
  border: 1px solid #4f90f2;
}
