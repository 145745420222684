.Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-radius: 6px;
  background-color: #3c3c3c;
  box-shadow: 1px 1px 7px 3px #0000002e;
  z-index: 350;
  font-family: 'Nunito', sans-serif;
  width: 160px;
  height: 42px;
  position: absolute;
}

.Container button[disabled] {
  z-index: 1 !important;
}

.DragHandle {
  z-index: 1;
}

.DragIcon {
  cursor: move;
  color: rgba(255, 255, 255, 0.5);
  margin: 0px 8px;
}


.PlayBtn {
  color: var(--kemu-color-success-lighten-40);
}

.ButtonGroup button:disabled {
  color: var(--kemu-color-light-darken-20) !important;
  opacity: 0.5 !important;
}

.ButtonGroup button svg {
  width: 32px;
  height: 32px;
}

.PlayBtn:hover:not([disabled]) {
  color: var(--kemu-color-success-lighten-50) !important;
  opacity: 0.8 !important;
}

.StopBtn {
  color: var(--kemu-color-danger-lighten-30);
}

.StopBtn:hover:not([disabled]) {
  color: var(--kemu-color-danger-lighten-50) !important;
  opacity: 0.8 !important;
}

.PauseBtn {
  color: var(--kemu-color-primary-lighten-30);
}

.PauseBtn:hover:not([disabled]) {
  color: var(--kemu-color-primary-lighten-50) !important;
  opacity: 0.8 !important;
}