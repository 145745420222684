.Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

:global(.ctgry-custom) .Wrapper {
  min-height: 57px;
}

.Paused {
  /* box-shadow: 0px 0px 10px 5px var(--kemu-color-warning-darken-20); */
  box-shadow: 0px 0px 10px 5px var(--kemu-color-warning-darken-20);
  animation: pulsate 1.5s ease-in-out infinite;
}

@keyframes pulsate {
  0% {
    box-shadow: 0px 0px 5px 2px var(--kemu-color-warning-darken-20);
  }
  50% {
    box-shadow: 0px 0px 15px 7px var(--kemu-color-warning-darken-20);
  }
  100% {
    box-shadow: 0px 0px 5px 2px var(--kemu-color-warning-darken-20);
  }
}

.ProcessingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  pointer-events: none;
}

.ProcessingOverlay :global(.ant-spin-dot-holder) { 
  color: var(--kemu-color-primary);
}

.ErrorContainer {
  position: absolute;
  bottom: -8px;
  right: -8px;
  z-index: 1;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.TickContainer {
  position: absolute;
  bottom: -11px;
  right: -5px;
  z-index: 1;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
  pointer-events: none;
  opacity: 0.8;
}


.ErrorIcon {
  cursor: pointer;
  font-size: 20px;
  color: var(--kemu-color-danger);
}

.TickIcon {
  font-size: 20px;
  color: var(--kemu-color-success);
}

.BreakpointIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--kemu-color-danger);
  position: absolute;
  bottom: -10px;
  right: calc(50% - 9px);
  box-shadow: 0px 0px 2px 1px white;
  border: 1px solid white;
  opacity: 0.8;
}